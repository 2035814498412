import { template as template_5c12338fc67346369e75bfb1d0de2d66 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5c12338fc67346369e75bfb1d0de2d66(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
