import { template as template_0f963e1d9eb64f5c91de15faf9524303 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0f963e1d9eb64f5c91de15faf9524303(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
