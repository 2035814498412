import { template as template_fac09b12657e4404a5fc9cecfd09ac5d } from "@ember/template-compiler";
const FKLabel = template_fac09b12657e4404a5fc9cecfd09ac5d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
